// dailySalesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getDailySalesApi } from 'apiRequest/daily-sales/dailySalesRequest';

// Define the types for the API response data
export interface Invoice {
  wayBill:number;
  DestinationServiceCentreName:string;
  GrandTotal:number;
  ApproximateItemsWeight:number;
  PaymentStatus:string;
  PaymentMethod:string;
  ExpressDelivery:string;
  Cash: number;
  Transfer: number;
  Pos:string;
  PaymentTypeReference:string;
  DeclarationOfValueCheck:string;
  CustomerType:string;
  UserName:string;
  DateCreated:Date|string
  ShipmentId:number


  // Add more fields as necessary
}

interface DailySalesState {
  dataWay: Invoice[];
  loading: boolean;
  error: string | null;
}

// Define the initial state using the DailySalesState interface
const initialState: DailySalesState = {
  dataWay: [],
  loading: false,
  error: null,
};

// Define the parameters for the thunk action
interface FetchDailySalesParams {
  startDate: string;
  endDate: string;
}

// Async thunk to call the API, returning Invoice[] as the payload
export const fetchDailySales = createAsyncThunk<Invoice[], FetchDailySalesParams>(
  'dailySales/fetchByDate',
  async ({ startDate, endDate }) => {
    const response = await getDailySalesApi(`StartDate=${startDate}&EndDate=${endDate}`);
    return response.data.Invoices; // Assuming `Invoices` is an array of `Invoice`
  }
);

// Create the slice using the defined state and async thunk
const dailySalesSlice = createSlice({
  name: 'dailySales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailySales.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDailySales.fulfilled, (state, action: PayloadAction<Invoice[]>) => {
        state.loading = false;
        state.dataWay = action.payload;
      })
      .addCase(fetchDailySales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch sales data';
      });
  },
});

export default dailySalesSlice.reducer;
