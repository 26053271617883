import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IShipmentStateProps } from "utils/interfaces";

export const shipmentStates: IShipmentStateProps = {
    senderType: "individual",
    senderPhoneNumber: "",
    senderCustomerCode: "",
    senderFirstName: "",
    senderLastName: "",
    senderEmail: "",
    senderGender: "",
    senderAddress: "",
    receiverPhoneNumber: "",
    receiverFullName: "",
    receiverEmail: "",
    receiverAddress: "",
    destinationCountry: "",
    destinationState: "",
    deliveryOption: "",
    station: "",
    locality: "",
    shipmentDescription: "",
    packageType: "",
    blackBook: "",
    shipmentValue: 0,
    shipmentType: "",
    shipmentKind: "",
    requirePickup: "",
    vat: 0,
    insurance: 0,
    shipmentSpecificationState: [],
    pickupOptions: "",
    manufacturerCountry: ""
}

export const shipmentSlice = createSlice({
    name: "shipment-state",
    initialState: shipmentStates,
    reducers: {
        setSenderPhoneNumber: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderPhoneNumber = action.payload
        },
        setSenderType: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderType = action.payload
        },
        setDestinationCountry: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.destinationCountry = action.payload
        },
        setSenderCustomerCode: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderCustomerCode = action.payload
        },
        setsenderFirstName: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderFirstName = action.payload
        },
        setSenderLastName: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderLastName = action.payload
        },
        setSenderEmail: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderEmail = action.payload
        },
        setSenderGender: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderGender = action.payload
        },
        setSenderAddress: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.senderAddress = action.payload
        },
        setDeliveryOption: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.deliveryOption = action.payload
        },
        setReceiverPhoneNumber: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.receiverPhoneNumber = action.payload
        },
        setReceiverFullName: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.receiverFullName = action.payload
        },
        setReceiverEmail: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.receiverEmail = action.payload
        },
        setReceiverAddress: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.receiverAddress = action.payload
        },
        setDestinationState: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.destinationState = action.payload
        },
        setLocality: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.station = action.payload
        },
        setShipmentDescription: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.shipmentDescription = action.payload
        },
        setPackageType: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.packageType = action.payload
        },
        setBlackBook: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.blackBook = action.payload
        },
        setShipmentValue: (state: IShipmentStateProps, action: PayloadAction<number>) => {
            state.shipmentValue = action.payload
        },
        setShipmentType: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.shipmentType = action.payload
        },
        setShipmentKind: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.shipmentKind = action.payload
        },
        setRequirePickup: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.requirePickup = action.payload
        },
        setVat: (state: IShipmentStateProps, action: PayloadAction<number>) => {
            state.vat = action.payload
        },
        setInsurance: (state: IShipmentStateProps, action: PayloadAction<number>) => {
            state.insurance = action.payload
        },
        setPickupOptions: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.pickupOptions = action.payload
        },
        setManufacturerCountry: (state: IShipmentStateProps, action: PayloadAction<string>) => {
            state.manufacturerCountry = action.payload
        },

        addShipmentSpecification: (state: IShipmentStateProps, action: PayloadAction<any>) => {
            state.shipmentSpecificationState = [...state.shipmentSpecificationState, action.payload]
        },
        removeShipmentSpecification: (state: any, action: PayloadAction<string | number>) => {
            console.log("spec removed-->>>>>>")
            const isItemExist = state.shipmentSpecificationState.find((item: any) => item.id === action.payload)
            console.log("<<<<< isItemExist >>>>> ", isItemExist)
            state.shipmentSpecificationState = state.shipmentSpecificationState.filter((item: any) => item.id !== action.payload)
        },
        updateSpecificationContent: (state: any, action: PayloadAction<any>) => {
            const { value, key, index} = action.payload
            // const itemIndex = state.shipmentSpecificationState.findIndex((item: any) => item.id === id)
            console.log("itemIndex>> ", state.shipmentSpecificationState[index])
            // if (itemIndex !== -1) {
            //     state.shipmentSpecificationState[itemIndex][key] = value //{...state.shipmentSpecificationState[itemIndex], value}
            // }
            state.shipmentSpecificationState[index][key] = value
            // state.shipmentSpecificationState[index] = {...state.shipmentSpecificationState[index], [key]: val}
        }
    }
})

export const shipmentActions = shipmentSlice.actions
export const shipmentReducer = shipmentSlice.reducer