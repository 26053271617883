import { back_arrow, btn_arrow_right_white } from 'assets'
import { Button } from 'components'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import OtpInput from 'react-otp-input';
import { useMutation } from '@tanstack/react-query';
import { validateOtpApi } from 'apiRequest/auth/authenticationRequest';
import { useSelector } from 'react-redux';
import { IRootState } from 'store/store';


export const ResetPasswordOtp = () => {
    const [otp, setOtp] = useState("")
    const navigate = useNavigate();
    const email = useSelector((state: IRootState) => state.auth.email);



  const {mutate} = useMutation({
    mutationFn: validateOtpApi,
    onError: (error: Error) => {
      console.log("error=== ", error);
      navigate("/forgot-password");
    },
    onSuccess: (res: any) => {
      console.log("res---- ", res);
      if(res){
        sessionStorage.setItem('agility_token',res.data['access-token'])
      }
      navigate("/reset-password")
      // navigate("/shipments/capture-shipments")
    }
  })

  const sendOtp = () => {
    console.log("login fxn clicked");
    mutate({
      otp: +(otp),
      email: email
      })
  }

  return (
    <div className="px-2">
    <p className='mb-6 text-[0.831rem] tb:text-[1rem] font-[500] text-col_525252'>
      We sent a code to youremailaddress@email.com
    </p>

    <div className='flex items-center gap-4'>
    <OtpInput
      value={otp}
      // setValue={setOtp}
      onChange={setOtp}
      numInputs={4}
      renderInput={(props) => <input {...props} />}
      shouldAutoFocus={true}
      inputStyle={`otp-input p-2 text-[1.2rem] text-black `} //{border: "2px solid red"}
    />
    </div>

    <Button
      text="confirm OTP"
      suffixIcon={btn_arrow_right_white}
      onClick={() => sendOtp()}
      className='w-full mt-8 text-col_ffffff px-6 py-4 flex items-center justify-center cursor-pointer text-[0.83rem] font-[600] rounded-[0.475rem] bg-col_e84e40'
    />

    <div className=' flex items-center justify-center gap-2 mt-6 '>
        <img src={back_arrow} alt="" className='cursor-pointer' onClick={() => navigate(-1)} />
        <span className='cursor-pointer font-fontInter text-txt_087_normal text-[#525252]' onClick={() => navigate(-1)}>Back to login</span>
    </div>
  </div>
  )
}