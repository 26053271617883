import {configureStore} from "@reduxjs/toolkit"
import { shipmentReducer } from "./slices/shipments/shipments"
import { agilityReducer } from "./slices/utils/utilSlice"
import authReducer from './slices/authLogin/authSlice';
import { IAgilityProps, IShipmentStateProps, ILoginProps } from "utils/interfaces"
// store.js
import dailySalesReducer from './slices/dailySales/dailySalesData';

export type IStoreProps = {
    shipmentReducer: IShipmentStateProps
    agilityReducer: IAgilityProps
    authReducer: ILoginProps
}

export const store = configureStore({
    reducer: {
        shipmentReducer: shipmentReducer,
        agilityReducer: agilityReducer,
        auth: authReducer,
        dailySales: dailySalesReducer
    }
})

export type IRootState = ReturnType<typeof store.getState>