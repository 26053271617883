
import { createContext, ReactNode, useState } from "react";
import { ICaptureShipmentContextProps, ISpecificationProps } from "utils/interfaces";

interface childProps {
    children: ReactNode
}

export const CaptureShipmentContext = createContext<ICaptureShipmentContextProps | null>(null)

export const CaptureShipmentContextProvider = ({ children }: childProps) => {
    const [senderType, setSenderType] = useState("individual")
    const [senderPhoneNumber, setSenderPhoneNumber] = useState("")
    const [senderCustomerCode, setSenderCustomerCode] = useState("")
    const [senderFirstName, setSenderFirstName] = useState("")
    const [senderLastName, setSenderLastName] = useState("")
    const [senderEmail, setSenderEmail] = useState("")
    const [senderGender, setSenderGender] = useState("")
    const [senderAddress, setSenderAddress] = useState("")
    const [deliveryOption, setDeliveryOption] = useState("")
    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("")
    const [receiverFullName, setReceiverFullName] = useState("")
    const [receiverEmail, setReceiverEmail] = useState("")
    const [destinationCountry, setDestinationCountry] = useState("")
    const [receiverAddress, setReceiverAddress] = useState("")
    const [destinationState, setDestinationState] = useState("")
    const [station, setStation] = useState("")
    const [locality, setLocality] = useState("")
    const [shipmentDescription, setShipmentDescription] = useState("")
    const [packageType, setPackageType] = useState("")
    const [blackBook, setBlackBook] = useState("")
    const [shipmentValue, setShipmentValue] = useState<number>()
    const [shipmentType, setShipmentType] = useState("go standard")
    const [shipmentKind, setShipmentKind] = useState("normal")
    const [requirePickup, setRequirePickup] = useState("yes")
    const [vat, setVat] = useState<number>()
    const [insurance, setInsurance] = useState<number>()
    const [specificationState, setSpecificationState] = useState<ISpecificationProps[]>([])
    const [pickupOption, setPickupOption] = useState("")
    const [multiShipment, setMultiShipment] = useState<any>([])  //<IMultiShipmentProp[]>([])
    const [senderData, setSenderData] = useState<any>({})
    const [receiverData, setReceiverData] = useState<any>({})
    const [shipmentData, setShipmentData] = useState<any>({})
    const [clearData, setClearData] = useState(false)
    const [shipmentSummary, setShipmentSummary] = useState<null | { Customer: { [key: string]: string | number }, Shipments: { [key: string]: string | number } }>(null)


    const addSpecificationState = (e: { [key: string]: string }) => {
        setSpecificationState((prev: any) => [...prev, e])
    }

    const filterSpecificationState = (id: string) => {
        setSpecificationState(specificationState.filter((item: { id: string; }) => item.id !== id))
    }

    const updateSpecificationContent = ({ id, key, value }: { id: string, key: string, value: string | number | boolean }) => {
        const index = specificationState.findIndex((item: any) => item.id === id)
        if (index !== -1) {
            //@ts-ignore
            specificationState[index][key] = value
        }
    }

    const clearSpecificationState = () => {
        setSpecificationState([])
    }

    const getSingleShipment = (id: string) => {
        const getSpec = specificationState.find((item: any) => item.id === id)
        return getSpec
    }

    const updateSenderData = ({ key, value }: any) => {
        setSenderData({ ...senderData, [key]: value })
    }

    const addSenderObject = (e: { [key: string]: string | number | boolean }) => {
        setSenderData(e)
    }

    const updateReceiverData = ({ id, key, value }: any) => {
        if (id) {
            setReceiverData({ id: id })
        }
        else if (key && value) {
            setReceiverData({ ...receiverData, [key]: value })
        }
        else {
            setReceiverData({})
        }
    }

    const addToMultiShipment = (e: { [key: string]: string }) => {
        setMultiShipment((prev: any) => [...prev, e])
    }

    const updateMultiShipmentContent = ({ id, key, value }: { id: string | number, key: string, value: string }) => {
        const index = multiShipment.findIndex((item: any) => item.id === id)
        if (index !== -1) {
            //@ts-ignore
            multiShipment[index][key] = value
        }
    }

    const updateShipmentData = ({ id, key, value, obj, arr }: any) => {
        if (id) {
            setShipmentData({ id: id })
        }
        else if (key && value && !id) {
            setShipmentData({ ...shipmentData, [key]: value })
        }
        else if (obj) {
            setShipmentData({ ...shipmentData, ...obj })
        }
        else if (key && arr) {
            setShipmentData({ ...shipmentData, [key]: arr })
        }
        else {
            setShipmentData({})
        }
    }

    return (
        <CaptureShipmentContext.Provider
            value={{
                specificationState,
                senderType,
                senderPhoneNumber,
                senderCustomerCode,
                senderFirstName,
                senderLastName,
                senderEmail,
                senderGender,
                senderAddress,
                receiverPhoneNumber,
                receiverFullName,
                receiverEmail,
                receiverAddress,
                destinationCountry,
                destinationState,
                deliveryOption,
                station,
                locality,
                shipmentDescription,
                packageType,
                blackBook,
                shipmentValue,
                shipmentType,
                shipmentKind,
                requirePickup,
                vat,
                insurance,
                pickupOption,
                multiShipment,
                senderData,
                shipmentData,
                receiverData,
                clearData,
                shipmentSummary,
                setSenderType,
                setSenderPhoneNumber,
                setSenderCustomerCode,
                setSenderFirstName,
                setSenderLastName,
                setSenderEmail,
                setSenderGender,
                setSenderAddress,
                setReceiverPhoneNumber,
                setReceiverFullName,
                setReceiverEmail,
                setReceiverAddress,
                setDestinationCountry,
                setDestinationState,
                setDeliveryOption,
                setStation,
                setLocality,
                setShipmentDescription,
                setPackageType,
                setBlackBook,
                setShipmentValue,
                setShipmentType,
                setShipmentKind,
                setRequirePickup,
                setVat,
                setInsurance,
                addSpecificationState,
                filterSpecificationState,
                updateSpecificationContent,
                setPickupOption,
                getSingleShipment,
                addToMultiShipment,
                updateMultiShipmentContent,
                updateSenderData,
                addSenderObject,
                updateShipmentData,
                setClearData,
                clearSpecificationState,
                updateReceiverData,
                setShipmentSummary
            }}
        >
            {children}
        </CaptureShipmentContext.Provider>
    )
}
